import React, {Component} from 'react';
import axios from 'axios';
import Iframe from 'react-iframe'

var dev_api = "http://18.219.181.97:8080"
var prod_api = "https://api.intermedia-datascience.net/"

export default class PBXFraud extends Component {
  constructor(props) {
    super(props);

    this.state = {key: ""}
  }

  componentDidMount() {
    console.log("API")
    axios.get(prod_api, {timeout:3000})
      .then(res => {
        console.log(res)
        const api_res = res.data;
        this.setState({ key : api_res });
      })
      .catch(err=> {
        console.log(err.code)
        this.setState({ key : "None" });
      })
  }

  render_dashboard =() => {
    var dashboard_elements
    if (this.state.key !== "") {
      if (this.state.key === "None") {
        dashboard_elements = <div>No server response - check you are connected to the Corporate VPN and try again</div>
      }
      else if (this.state.key.startsWith("No response")) {
        dashboard_elements = 
        <div>
          {this.state.key}
        </div>
      }
      else {
        dashboard_elements = 
        <div>
          <Iframe url={"https://tableau.intermedia-datascience.net/trusted/"+this.state.key+"/views/Superstore/Overview?:embed=yes"}
            width="100%"
            height="800px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"/>
        </div>
      }
    }
    return(
      dashboard_elements
    )
  }


  render() {
    return (
      <div style={{textAlign: "left", fontSize: "100%", marginTop: "20px", whiteSpace: "pre-line"}}>
        <h4>PBX Fraud Detection</h4>
        {this.render_dashboard()}
      </div>
    )
  }
}