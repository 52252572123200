import React from 'react';
import logo from './logo.svg';
import { Switch, Route } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import './App.css';
import Home from './components/Home.js';
import PBXFraud from './components/PBXFraud.js';
import DSNavbar from './components/Navbar.js';
import MyTheme from "./themes/AmplifyTheme.js";
import { Container} from 'reactstrap';

Amplify.configure(awsconfig);

const authTheme = {
  button: { backgroundColor: "green", borderColor: "red" },
}

function App() {
  return (
    <div className="App">
      <DSNavbar/>
      <Container>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/PBXFraud' component={PBXFraud}/>
        </Switch>
      </Container>

    </div>
  );
}

export default withAuthenticator(App, false, [], null, authTheme);
