import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container} from 'reactstrap';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavLink
} from 'reactstrap';
import SignOut from  './SignOut.js';



class DSNavbar extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isOpen: false
      };
  
      this.toggle = this.toggle.bind(this);
    }



    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    render() {
        return (
            <div>
                <Navbar color="light" light expand="md">
                    <Container>
                        <Link to="/"><img src="/intm-logo.svg" height={40} style={{marginRight: "20px"}}></img>
                        <NavbarBrand href="/">Intermedia Data Science Portal</NavbarBrand></Link>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <UncontrolledDropdown nav inNavbar style={{marginRight: "20px"}}>
                                <DropdownToggle nav caret>
                                    Dashboards
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                    <NavItem><Link to='/PBXFraud' style={{textDecoration:"none", color: "#111111"}}>PBX Fraud Detection</Link></NavItem>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        {/* <NavItem>
                            <NavLink href='/page1'>Page 1</NavLink>
                        </NavItem> */}
                        <NavItem>
                            <SignOut />
                        </NavItem>
                        </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}
export default DSNavbar