import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => (
    <div style={{textAlign: "left", fontSize: "100%", marginTop: "20px", whiteSpace: "pre-line"}}>
      <h4>Data Science Portal</h4>
      <div style={{textAlign: "left", fontSize: "100%", marginTop: "20px", whiteSpace: "pre-wrap"}}>
        This is the internal Intermedia Data Science Portal, designed to be a single host for all prototype Data Science Projects. {"\n"} 
        Find links to projects below:
        <ul> 
          <li><Link to="/PBXFraud">PBX Fraud Detection</Link></li>
        </ul>
      </div>
      <span> </span>
    </div>
)

export default Home