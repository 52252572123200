/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:e9d1d4be-120e-4ae7-9ec2-e57a49092ea5",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_BGoCukvco",
    "aws_user_pools_web_client_id": "20ekv7f8toffifvgo55eqkgvhj",
    "oauth": {}
};


export default awsmobile;
